import { postVoid } from '../API/fetch';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { isPresent } from '../utils/helpers';
import { useEffect, useMemo } from 'react';
import ErrorAlert from '../components/ErrorAlert';
import { PageLoadingSpinner } from '../components/LoadingSpinner';

export interface LoginRequest {
  code: string;
}

const login = async (
  isCsrfTokenValid: boolean,
  code: string | null,
): Promise<void> => {
  if (!isCsrfTokenValid || !isPresent(code)) {
    throw new Error('System error has occurred');
  }

  const loginData: LoginRequest = {
    code,
  };

  await postVoid('/auth/login', {
    body: JSON.stringify(loginData),
  });
};

const Login = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const { stateObj, isCsrfTokenValid } = useMemo(() => {
    let stateObj;

    try {
      stateObj = isPresent(state)
        ? (JSON.parse(window.atob(state)) as {
            redirectUrl: string;
            guid: string;
          })
        : undefined;
    } catch {
      stateObj = undefined;
    }

    const isCsrfTokenValid =
      stateObj?.guid === window.sessionStorage.getItem('stateGuid');

    return { stateObj, isCsrfTokenValid };
  }, [state]);

  const { isError, error, isPending, mutate } = useMutation({
    mutationFn: () => login(isCsrfTokenValid, code),
    onSuccess: async () => {
      const result = navigate(stateObj?.redirectUrl ?? '/');

      if (result instanceof Promise) {
        await result;
      }
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <>
      {isError && <ErrorAlert error={error} />}
      {isPending && <PageLoadingSpinner />}
    </>
  );
};

export default Login;
