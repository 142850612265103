import { Inline, paletteHelper, Tooltip } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

interface Props {
  title: string;
  messages: string[] | undefined;
  type?: 'error' | 'warning';
}

const InlineError = styled(Inline)`
  color: ${paletteHelper('textError')};
`;

const TooltipError = styled(Tooltip)`
  > button {
    color: ${paletteHelper('textError')};
  }
  white-space: pre-wrap;
`;

const TooltipWarning = styled(Tooltip)`
  > button {
    color: ${paletteHelper('textWarningInverted')};
  }
  white-space: pre-wrap;
`;

const InlineWarning = styled(Inline)`
  color: ${paletteHelper('textWarningInverted')};
`;

const AlertTooltip = ({
  title,
  messages,
  type = 'error',
}: Props): JSX.Element | null => {
  if (!messages || messages.length === 0) {
    return null;
  }

  return type === 'error' ? (
    <InlineError>
      {title} <TooltipError>{messages.join('\n')}</TooltipError>
    </InlineError>
  ) : (
    <InlineWarning>
      {title} <TooltipWarning>{messages.join('\n')}</TooltipWarning>
    </InlineWarning>
  );
};

export default AlertTooltip;
