import { H1, Stack } from '@rea-group/construct-kit-core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../../components/ErrorAlert';
import TargetingModForm, {
  TargetingModFormValues,
} from '../../components/targetingMods/TargetingModForm';
import { TargetingMod, TargetingModRequest } from './types';
import { fromTargetingModResponse } from './utils';
import { PageLoadingSpinner } from '../../components/LoadingSpinner';

const TargetingModContainer = styled.div`
  max-width: 30rem;
  margin: 0 auto;
`;

type Params = {
  targetId: string;
};

type Variables = {
  values: TargetingModRequest;
  targetId: string;
};

const getTargetingModData = async (
  targetId: string,
): Promise<TargetingModFormValues> => {
  const targetingMod = await fetchJson<TargetingMod>(
    `/targeting-mods/${targetId}`,
  );

  return fromTargetingModResponse(targetingMod);
};

const updateTargetingMod = async ({
  values,
  targetId,
}: Variables): Promise<TargetingMod> =>
  fetchJson<TargetingMod>(`/targeting-mods/${targetId}`, {
    method: 'PUT',
    body: JSON.stringify(values),
  });

const EditTargetingMod = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { targetId } = useParams<Params>() as Params;

  const {
    isLoading,
    isError,
    error,
    data: targetingModData,
  } = useQuery({
    queryKey: ['editTargetingMod', targetId],
    queryFn: () => getTargetingModData(targetId),
  });

  const navigate = useNavigate();

  const updateMutation = useMutation({
    mutationFn: updateTargetingMod,
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ['editTargetingMod', data.id.toString()],
        fromTargetingModResponse(data),
      );
      await queryClient.invalidateQueries({ queryKey: ['targetingModsData'] });

      const result = navigate('/targeting-mods');

      if (result instanceof Promise) {
        await result;
      }
    },
  });

  return (
    <TargetingModContainer>
      <Stack>
        <H1>Edit Target</H1>
        {isLoading && <PageLoadingSpinner />}
        {(isError || updateMutation.isError) && (
          <ErrorAlert error={error || updateMutation.error} />
        )}
        {targetingModData && (
          <TargetingModForm
            onSave={(values: TargetingModRequest) => {
              updateMutation.mutate({ values, targetId });
            }}
            defaultValues={targetingModData}
            isLoading={updateMutation.isPending}
          />
        )}
      </Stack>
    </TargetingModContainer>
  );
};

export default EditTargetingMod;
