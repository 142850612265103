import { Button, Stack } from '@rea-group/construct-kit-core';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { putVoid } from '../API/fetch';
import ErrorAlert from './ErrorAlert';

const StyledButton = styled(Button)`
  max-width: 9rem;
`;

const completeJobs = (): Promise<void> => putVoid('/jobs/complete');

const JobQueueComplete = (): JSX.Element => {
  const navigate = useNavigate();

  const completeJobsMutation = useMutation({
    mutationFn: completeJobs,
    onSuccess: async () => {
      const result = navigate('/');

      if (result instanceof Promise) {
        await result; // Await if it's a promise
      }
    },
  });

  return (
    <Stack>
      {completeJobsMutation.isError && (
        <ErrorAlert error={completeJobsMutation.error} />
      )}
      <StyledButton
        variant="primary"
        sizeVariant="medium"
        onClick={() => {
          completeJobsMutation.mutate();
        }}
      >
        Complete
      </StyledButton>
    </Stack>
  );
};

export default JobQueueComplete;
