import { Button, H1, Inline, Link, Stack } from '@rea-group/construct-kit-core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { fetchJson, requestDelete } from '../../API/fetch';
import ConfirmModal from '../../components/ConfirmModal';
import ErrorAlert from '../../components/ErrorAlert';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../../components/Table/Table';
import { isPresent } from '../../utils/helpers';
import { TargetingMod } from './types';
import { PageLoadingSpinner } from '../../components/LoadingSpinner';

export interface TargetingModsResponseData {
  targetingMods: TargetingMod[];
}

const formatPriceRange = (
  priceMin: number | null,
  priceMax: number | null,
): string => {
  const min = priceMin ?? 'Any';
  const max = priceMax ?? 'Any';
  return `${min} to ${max}`;
};

const deleteTargetingMod = (targetId: number): Promise<void> =>
  requestDelete(`/targeting-mods/${targetId}`);

const TargetingMods = (): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    refetch: refetchTargetingModsData,
    data: targetingModsData,
  } = useQuery({
    queryKey: ['targetingModsData'],
    queryFn: () => fetchJson<TargetingModsResponseData>('/targeting-mods'),
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deleteTargetIdRef = useRef<number>(null);

  const closeModalAndResetDeleteMutation = (): void => {
    setIsDeleteModalOpen(false);
    deleteMutation.reset();
    deleteTargetIdRef.current = null;
  };

  const deleteMutation = useMutation({
    mutationFn: deleteTargetingMod,
    onSuccess: async () => {
      closeModalAndResetDeleteMutation();
      await refetchTargetingModsData();
    },
  });

  return (
    <Stack gap="medium">
      <H1>Targeting Mods</H1>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {targetingModsData && (
        <>
          <Table>
            <TableHeader>
              <TableHeaderColumn>ID</TableHeaderColumn>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Postcodes</TableHeaderColumn>
              <TableHeaderColumn>Price</TableHeaderColumn>
              <TableHeaderColumn>State</TableHeaderColumn>
              <TableHeaderColumn>Property Type</TableHeaderColumn>
              <TableHeaderColumn>Segment</TableHeaderColumn>
              <TableHeaderColumn> </TableHeaderColumn>
            </TableHeader>
            <TableBody>
              {targetingModsData.targetingMods.length === 0 ? (
                <EmptyTableBody />
              ) : (
                targetingModsData.targetingMods.map(
                  ({
                    id,
                    name,
                    postcodes,
                    state,
                    propertyTypes,
                    priceMin,
                    priceMax,
                    segment,
                  }) => (
                    <TableRow key={id}>
                      <TableContentColumn>{id}</TableContentColumn>
                      <TableContentColumn>{name}</TableContentColumn>
                      <TableContentColumn noWrap={false}>
                        {postcodes}
                      </TableContentColumn>
                      <TableContentColumn>
                        {formatPriceRange(priceMin, priceMax)}
                      </TableContentColumn>
                      <TableContentColumn>{state}</TableContentColumn>
                      <TableContentColumn noWrap={false}>
                        {propertyTypes}
                      </TableContentColumn>
                      <TableContentColumn>{segment}</TableContentColumn>
                      <TableContentColumn>
                        <Inline
                          gap="small"
                          grow={false}
                          justifyContent={'center'}
                        >
                          <Link
                            as={RouterLink}
                            to={`/targeting-mods/edit/${id}`}
                          >
                            Edit
                          </Link>
                          <Button
                            onClick={() => {
                              deleteTargetIdRef.current = id;
                              setIsDeleteModalOpen(true);
                            }}
                            variant="link-primary"
                          >
                            Delete
                          </Button>
                        </Inline>
                      </TableContentColumn>
                    </TableRow>
                  ),
                )
              )}
            </TableBody>
          </Table>
        </>
      )}
      <Link
        as={RouterLink}
        to="/targeting-mods/create"
        variant="button-primary"
      >
        Create target
      </Link>
      <ConfirmModal
        headerText="Are you sure you want to delete?"
        okButtonText="Delete"
        isOpen={isDeleteModalOpen}
        isError={deleteMutation.isError}
        error={deleteMutation.error}
        isOkButtonDisabled={deleteMutation.isPending}
        onClose={closeModalAndResetDeleteMutation}
        onCancel={closeModalAndResetDeleteMutation}
        onOk={() =>
          isPresent(deleteTargetIdRef.current) &&
          deleteMutation.mutate(deleteTargetIdRef.current)
        }
      />
    </Stack>
  );
};

export default TargetingMods;
