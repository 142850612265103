import { useRef, useState } from 'react';

import { Inline, TextInput } from '@rea-group/construct-kit-core';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { DateRange } from 'react-day-picker/src/types';
import styled from 'styled-components';
import { isPresent } from '../../utils/helpers';
import { DatePickerContainer } from './DatePicker.styles';
import { useHandleClickOutside } from './useHandleClickOutside';

const RangeDatePickerContainer = styled.div`
  position: relative;
`;

interface RangeDatePickerProps {
  placeHolders: { from: string; to: string };
  dateRange: DateRange;
  setDateRange: (arg: DateRange) => void;
  footer?: JSX.Element;
}

export const RangeDatePicker = ({
  placeHolders,
  dateRange,
  setDateRange,
  footer,
}: RangeDatePickerProps): JSX.Element => {
  const datePickerContainer = useRef<HTMLDivElement>(null);
  useHandleClickOutside(datePickerContainer, () => setIsShowDatePicker(false));
  const { from, to } = dateRange;
  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

  const handleRangeSelect = (range: DateRange | undefined): void => {
    if (isPresent(range)) {
      setDateRange(range);
    }
  };

  const togglePickerVisibility = (): void => {
    setIsShowDatePicker(!isShowDatePicker);
  };

  return (
    <RangeDatePickerContainer ref={datePickerContainer}>
      <Inline>
        <TextInput
          sizeVariant="medium"
          label="From"
          placeholder={placeHolders.from}
          value={isPresent(from) ? format(from, 'dd/MM/y') : ''}
          onFocus={togglePickerVisibility}
        />
        <TextInput
          sizeVariant="medium"
          label="To"
          placeholder={placeHolders.to}
          value={isPresent(to) ? format(to, 'dd/MM/y') : ''}
          onFocus={togglePickerVisibility}
        />
      </Inline>
      {isShowDatePicker && (
        <DatePickerContainer>
          <DayPicker
            mode="range"
            selected={dateRange}
            onSelect={handleRangeSelect}
            disabled={{ after: new Date() }}
            numberOfMonths={2}
            footer={footer}
          />
        </DatePickerContainer>
      )}
    </RangeDatePickerContainer>
  );
};

export default RangeDatePicker;
