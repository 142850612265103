import { Button, Inline, Stack } from '@rea-group/construct-kit-core';
import { formatISO } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'react-day-picker/src/types';
import { downloadFile } from '../../utils/downloadFile';
import { isPresent } from '../../utils/helpers';
import RangeDatePicker from '../DatePicker/RangeDatePicker';
import Panel from './Panel';

const PerformanceReportPanel = (): JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const downloadReportButtonHandler = (): void => {
    if (isPresent(dateRange.from) && isPresent(dateRange.to)) {
      const startDate = formatISO(dateRange.from, { representation: 'date' });
      const endDate = formatISO(dateRange.to, { representation: 'date' });

      downloadFile(
        `${process.env.API_ROOT}/report/download?startDate=${startDate}&endDate=${endDate}`,
      );
    }
  };

  return (
    <Panel heading="AMAX Performance Report" showDivider={false}>
      <Stack gap="medium">
        <Stack gap="twoExtraSmall">
          <Inline gap="extraSmall" alignItems="end">
            <RangeDatePicker
              placeHolders={{ from: 'Start date', to: 'End date' }}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Button
              variant="primary"
              sizeVariant="medium"
              disabled={!isPresent(dateRange.from) || !isPresent(dateRange.to)}
              onClick={downloadReportButtonHandler}
            >
              Download report
            </Button>
          </Inline>
        </Stack>
      </Stack>
    </Panel>
  );
};

export default PerformanceReportPanel;
