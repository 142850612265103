import { AnyObjectSchema, ValidationError } from 'yup';

export const safeParse = <T>(
  schema: AnyObjectSchema,
  data: unknown,
): { ok: true; data: T } | { ok: false; errors: Record<string, string> } => {
  try {
    const validatedData = schema.validateSync(data, {
      abortEarly: false,
    }) as T;

    return { ok: true, data: validatedData };
  } catch (error) {
    if (!(error instanceof ValidationError)) {
      throw error;
    }

    const errors = error.inner.reduce(
      (acc, err) => {
        if (err.path) {
          acc[err.path] = err.message;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    return { ok: false, errors };
  }
};
