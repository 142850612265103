import { Link } from '@rea-group/construct-kit-core';
import ImagesLink, { ImagesJobLink, JobError } from './ImagesLink';
import Status from './Status';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  EmptyTableBody,
  TableRow,
  TableContentColumn,
} from './Table/Table';
import { ReactNode } from 'react';
import ExternalLink from './ExternalLink';
import { isPresent } from '../utils/helpers';
import ErrorLinkMessage from './ErrorLinkMessage';

export interface Job {
  id: number;
  bookingId: string;
  listingId: number;
  status: string;
  imagesLink: ImagesJobLink;
  facebookLink: JobLink;
  yahooLink: JobLink;
  yahooNativeLink: JobLink;
  adformLink: JobLink;
  tradedeskLink: JobLink;
}

type JobLink = string | JobError | null;

interface JobLinkProps {
  link: JobLink;
  children: ReactNode;
}

export const JobLink = ({
  link,
  children,
}: JobLinkProps): JSX.Element | null => {
  if (!isPresent(link)) {
    return null;
  } else if (typeof link === 'string') {
    return <ExternalLink href={link}>{children}</ExternalLink>;
  }

  return <ErrorLinkMessage messageText={link.message} />;
};

export const JobsTable = ({ jobs }: { jobs: Job[] }): JSX.Element => (
  <Table>
    <TableHeader>
      <TableHeaderColumn>Booking ID</TableHeaderColumn>
      <TableHeaderColumn>Listing ID</TableHeaderColumn>
      <TableHeaderColumn>Status</TableHeaderColumn>
      <TableHeaderColumn>Images</TableHeaderColumn>
      <TableHeaderColumn>Facebook</TableHeaderColumn>
      <TableHeaderColumn>Yahoo Display</TableHeaderColumn>
      <TableHeaderColumn>Yahoo Native</TableHeaderColumn>
      <TableHeaderColumn>Adform</TableHeaderColumn>
      <TableHeaderColumn>Tradedesk</TableHeaderColumn>
    </TableHeader>
    <TableBody>
      {jobs.length === 0 ? (
        <EmptyTableBody />
      ) : (
        jobs.map(
          ({
            id,
            bookingId,
            listingId,
            status,
            imagesLink,
            facebookLink,
            yahooLink,
            yahooNativeLink,
            adformLink,
            tradedeskLink,
          }) => (
            <TableRow key={id}>
              <TableContentColumn>{bookingId}</TableContentColumn>
              <TableContentColumn>
                <Link
                  href={`https://www.realestate.com.au/${listingId}`}
                  target="_blank"
                >
                  {listingId}
                </Link>
              </TableContentColumn>
              <TableContentColumn>
                <Status status={status} />
              </TableContentColumn>
              <TableContentColumn>
                <ImagesLink link={imagesLink} listingId={listingId}>
                  Images link
                </ImagesLink>
              </TableContentColumn>
              <TableContentColumn>
                {facebookLink === 'N/A' ? (
                  facebookLink
                ) : (
                  <JobLink link={facebookLink}>Facebook link</JobLink>
                )}
              </TableContentColumn>
              <TableContentColumn>
                {yahooLink === 'N/A' ? (
                  yahooLink
                ) : (
                  <JobLink link={yahooLink}>Yahoo Display link</JobLink>
                )}
              </TableContentColumn>
              <TableContentColumn>
                {yahooNativeLink === 'N/A' ? (
                  yahooNativeLink
                ) : (
                  <JobLink link={yahooNativeLink}>Yahoo Native link</JobLink>
                )}
              </TableContentColumn>
              <TableContentColumn>
                {adformLink === 'N/A' ? (
                  adformLink
                ) : (
                  <JobLink link={adformLink}>Adform link</JobLink>
                )}
              </TableContentColumn>
              <TableContentColumn>
                {tradedeskLink === 'N/A' ? (
                  tradedeskLink
                ) : (
                  <JobLink link={tradedeskLink}>Tradedesk link</JobLink>
                )}
              </TableContentColumn>
            </TableRow>
          ),
        )
      )}
    </TableBody>
  </Table>
);
