import {
  Button,
  Card,
  H1,
  Inline,
  Label,
  spacingHelper,
  Stack,
  Strong,
  Text,
  TextInput,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { Booking } from './types';
import SingleDatePicker from '../DatePicker/SingleDatePicker';
import { isPresent } from '../../utils/helpers';
import { parse } from 'date-fns';
import { useActionState, useState } from 'react';
import { fetchJson } from '../../API/fetch';
import { editBookingFormSchema } from './bookingFormSchema';
import { safeParse } from '../../utils/validation';
import { useQueryClient } from '@tanstack/react-query';
import { v4 } from 'uuid';

const ContentContainer = styled(Card)`
  padding: ${spacingHelper('medium')};
`;

const StyledSingleDatePicker = styled(SingleDatePicker)`
  width: 100%;
  input {
    height: 2rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }
`;

const StyledStack = styled(Stack).attrs({
  gap: 'medium',
})`
  margin: 0 0 0.5rem 0.5rem;
`;

const StyledFooter = styled(Inline)`
  margin-top: 1rem;

  & > * {
    flex: 1 1 0;
  }
`;

const AlertCard = styled(Card)<{ type: 'error' | 'warning' }>`
  padding: ${spacingHelper('medium')};
  border: ${({ type }) =>
    type === 'error' ? '1px solid #A81E35' : '1px solid #FFA857'};
`;

interface Props {
  booking?: Booking;
  onCancel: () => void;
  onSave: () => void;
}

type FormState =
  | {
      ok: boolean;
      errors?: Record<string, string>;
      message?: string;
    }
  | undefined
  | null;

const updateBookingAction = async (
  state: FormState,
  formData: FormData,
  postUpdate?: () => Promise<void>,
): Promise<FormState> => {
  const data = Object.fromEntries(formData.entries());

  const result = safeParse<Booking>(editBookingFormSchema, data);

  if (!result.ok) {
    return {
      ok: false,
      errors: result.errors,
    };
  }

  const booking = result.data;

  await fetchJson<Booking>(`/bookings/${booking.bookingId}`, {
    method: 'PUT',
    body: JSON.stringify(booking),
  });

  if (postUpdate) {
    await postUpdate();
  }
};

const parseDate = (dateString?: string): Date | undefined =>
  dateString ? parse(dateString, 'dd/MM/yyyy', new Date()) : undefined;

const EditBookingForm = ({
  booking,
  onCancel,
  onSave,
}: Props): JSX.Element | null => {
  const queryClient = useQueryClient();

  const postUpdate = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: ['getInvalidBookings'] });

    if (onSave) {
      onSave();
    }
  };

  const [state, action, isPending] = useActionState(
    (state: FormState, formData: FormData) =>
      updateBookingAction(state, formData, postUpdate),
    undefined,
  );

  const [startDate, setStartDate] = useState(parseDate(booking?.startDate));

  if (!isPresent(booking)) {
    return null;
  }

  const {
    bookingId,
    listingId,
    bookingPeriod,
    impressions,
    listingPostcode,
    source,
    listingSuburb,
    customTargeting,
    listingPrice,
    listingState,
    listingStreetAddress,
    propertyType,
    agencyId,
  } = booking;

  const renderErrorsOrWarnings = ({
    title,
    messages,
    type = 'error',
  }: {
    title: string;
    messages: string[] | undefined;
    type?: 'error' | 'warning';
  }): JSX.Element | null => {
    if (!isPresent(messages) || messages.length === 0) {
      return null;
    }

    return (
      <>
        <Text>
          <Strong>{title}</Strong>
        </Text>
        <AlertCard borderRadius="medium" type={type}>
          {messages.map((error) => (
            <div key={v4()}>{error}</div>
          ))}
        </AlertCard>
      </>
    );
  };

  return (
    <form action={action}>
      <ContentContainer>
        <StyledStack gap="medium">
          <H1>Edit Booking</H1>
          <Inline gap="medium">
            <TextInput
              name="bookingId"
              label="Booking ID"
              defaultValue={bookingId}
              errorMessage={state?.errors?.bookingId}
            />
            <TextInput
              name="listingId"
              label="Listing ID"
              defaultValue={listingId}
              errorMessage={state?.errors?.listingId}
            />
          </Inline>
          <Inline gap="medium">
            <Stack gap="twoExtraSmall">
              <Label htmlFor="startDate">Start Date</Label>
              <StyledSingleDatePicker
                placeHolder="Start Date"
                name="startDate"
                date={startDate}
                onDateChange={(value) => setStartDate(value)}
              />
            </Stack>
            <TextInput
              name="bookingPeriod"
              label="Booking Period"
              defaultValue={bookingPeriod}
              errorMessage={state?.errors?.bookingPeriod}
            />
          </Inline>
          <Inline gap="medium">
            <TextInput
              name="listingStreetAddress"
              label="Listing Address"
              defaultValue={listingStreetAddress}
              errorMessage={state?.errors?.listingStreetAddress}
            />
            <TextInput
              name="listingSuburb"
              label="Listing Suburb"
              defaultValue={listingSuburb}
              errorMessage={state?.errors?.listingSuburb}
            />
          </Inline>
          <Inline gap="medium">
            <TextInput
              name="listingState"
              label="Listing State"
              defaultValue={listingState}
              errorMessage={state?.errors?.listingState}
            />
            <TextInput
              name="listingPostcode"
              label="Listing Postcode"
              defaultValue={listingPostcode}
              errorMessage={state?.errors?.listingPostcode}
            />
          </Inline>
          <Inline gap="medium">
            <TextInput
              name="listingPrice"
              label="Listing Price"
              defaultValue={listingPrice}
              errorMessage={state?.errors?.listingPrice}
            />
            <TextInput
              name="propertyType"
              label="Property Type"
              value={propertyType}
              errorMessage={state?.errors?.propertyType}
            />
          </Inline>
          <Inline gap="medium">
            <TextInput
              name="impressions"
              label="Impressions"
              defaultValue={impressions}
              errorMessage={state?.errors?.impressions}
            />
            <TextInput
              name="agencyId"
              label="Agency Id"
              value={agencyId}
              errorMessage={state?.errors?.agencyId}
            />
          </Inline>
          <Inline gap="medium">
            <TextInput
              name="source"
              label="Source"
              value={source}
              errorMessage={state?.errors?.source}
            />
            <TextInput
              name="customTargeting"
              label="Custom Targeting"
              defaultValue={customTargeting ?? ''}
              errorMessage={state?.errors?.customTargeting}
            />
          </Inline>
          {renderErrorsOrWarnings({
            title: 'Errors',
            messages: booking.errorsOrWarnings?.errors,
          })}
          {renderErrorsOrWarnings({
            title: 'Warnings',
            messages: booking.errorsOrWarnings?.warnings,
            type: 'warning',
          })}
        </StyledStack>
        <StyledStack>
          <StyledFooter gap="medium" justifyContent="space-between">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={isPending}>
              Update
            </Button>
          </StyledFooter>
        </StyledStack>
      </ContentContainer>
    </form>
  );
};

export default EditBookingForm;
