import 'react-day-picker/style.css';
import styled from 'styled-components';
import { paletteHelper } from '@rea-group/construct-kit-core';

export const DatePickerContainer = styled.div`
  z-index: 1;
  background-color: white;
  position: absolute;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0 2px 6px,
    rgba(0, 0, 0, 0.07) 0 0 0 1px;
  border-radius: 3px;

  .rdp-root {
    --rdp-range_middle-background-color: ${paletteHelper(
      'backgroundHoveredPrimary',
    )};

    --rdp-accent-color: ${paletteHelper('backgroundSelected')};

    margin: 0.5rem;

    .rdp-months {
      flex-wrap: nowrap;
    }

    .rdp-today {
      font-weight: bolder;
    }

    .rdp-day.rdp-selected:not(.rdp-range_middle) {
      button {
        background-color: ${paletteHelper('backgroundSelected')};
        color: ${paletteHelper('textInverted')};
      }
    }
  }
`;
