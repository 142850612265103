import {
  Button,
  Link,
  Modal,
  Inline,
  Dialog,
} from '@rea-group/construct-kit-core';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  EmptyTableBody,
  TableRow,
  TableContentColumn,
} from '../Table/Table';
import { Booking } from './types';
import { useReducer } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import EditBookingForm from './EditBookingForm';
import { getAppRoot } from '../../utils/getAppRoot';
import ConfirmModal from '../ConfirmModal';
import { isPresent } from '../../utils/helpers';
import styled from 'styled-components';
import { requestDelete } from '../../API/fetch';
import AlertTooltip from '../AlertTooltip';

const StyledDialog = styled(Dialog)`
  min-width: 50vw;
`;

const deleteBooking = async (bookingId: number): Promise<void> => {
  await requestDelete(`/bookings/${bookingId}`);
};

interface State {
  editBooking?: Booking;
  deleteBooking?: Booking;
  isEditModalOpen: boolean;
  isDeleteModalOpen: boolean;
}

const initialState: State = {
  editBooking: undefined,
  deleteBooking: undefined,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
};

type Action =
  | {
      type: 'openEditModal';
      payload: { editBooking: Booking };
    }
  | { type: 'closeEditModal' }
  | { type: 'openDeleteModal'; payload: { deleteBooking: Booking } }
  | { type: 'closeDeleteModal' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'openEditModal':
      return {
        ...state,
        isEditModalOpen: true,
        editBooking: action.payload.editBooking,
      };
    case 'closeEditModal':
      return { ...state, isEditModalOpen: false, editBooking: undefined };
    case 'openDeleteModal':
      return {
        ...state,
        isDeleteModalOpen: true,
        deleteBooking: action.payload.deleteBooking,
      };
    case 'closeDeleteModal':
      return { ...state, isDeleteModalOpen: false, deleteBooking: undefined };
    default:
      return state;
  }
};

interface Props {
  bookings: Booking[];
}

export const BookingsTable = ({ bookings }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOpenEditModal = (booking: Booking): void => {
    dispatch({
      type: 'openEditModal',
      payload: {
        editBooking: booking,
      },
    });
  };

  const handleCloseEditModal = (): void => {
    dispatch({
      type: 'closeEditModal',
    });
  };

  const handleOpenDeleteModal = (booking: Booking): void => {
    dispatch({
      type: 'openDeleteModal',
      payload: {
        deleteBooking: booking,
      },
    });
  };

  const handleCloseDeleteModal = (): void => {
    updateMutation.reset();
    dispatch({
      type: 'closeDeleteModal',
    });
  };

  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: deleteBooking,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getInvalidBookings'] });
      handleCloseDeleteModal();
    },
  });

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Errors/Warnings</TableHeaderColumn>
          <TableHeaderColumn>Start Date</TableHeaderColumn>
          <TableHeaderColumn>Booking Period</TableHeaderColumn>
          <TableHeaderColumn>Impressions</TableHeaderColumn>
          <TableHeaderColumn>Listing Price</TableHeaderColumn>
          <TableHeaderColumn>Listing Address</TableHeaderColumn>
          <TableHeaderColumn>Listing Suburb</TableHeaderColumn>
          <TableHeaderColumn>Listing State</TableHeaderColumn>
          <TableHeaderColumn>Source</TableHeaderColumn>
          <TableHeaderColumn>Custom Targeting</TableHeaderColumn>
          <TableHeaderColumn>Date Created</TableHeaderColumn>
          <TableHeaderColumn>Action</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map((booking) => (
              <TableRow key={booking.id}>
                <TableContentColumn>{booking.bookingId}</TableContentColumn>
                <TableContentColumn>
                  <Link
                    href={`https://www.realestate.com.au/${booking.listingId}`}
                    target="_blank"
                  >
                    {booking.listingId}
                  </Link>
                </TableContentColumn>
                <TableContentColumn>
                  <AlertTooltip
                    title="Errors"
                    messages={booking.errorsOrWarnings?.errors}
                  />
                  <AlertTooltip
                    title="Warnings"
                    messages={booking.errorsOrWarnings?.warnings}
                    type="warning"
                  />
                </TableContentColumn>
                <TableContentColumn>{booking.startDate}</TableContentColumn>
                <TableContentColumn>{booking.bookingPeriod}</TableContentColumn>
                <TableContentColumn>{booking.impressions}</TableContentColumn>
                <TableContentColumn>{booking.listingPrice}</TableContentColumn>
                <TableContentColumn>
                  {booking.listingStreetAddress}
                </TableContentColumn>
                <TableContentColumn>{booking.listingSuburb}</TableContentColumn>
                <TableContentColumn>{booking.listingState}</TableContentColumn>
                <TableContentColumn>{booking.source}</TableContentColumn>
                <TableContentColumn>
                  {booking.customTargeting}
                </TableContentColumn>
                <TableContentColumn>{booking.dateCreated}</TableContentColumn>
                <TableContentColumn>
                  <Inline gap="small" grow={false} justifyContent="center">
                    <Button
                      variant="link-primary"
                      onClick={() => {
                        handleOpenEditModal(booking);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        handleOpenDeleteModal(booking);
                      }}
                      variant="link-primary"
                    >
                      Delete
                    </Button>
                  </Inline>
                </TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <ConfirmModal
        headerText={`Are you sure you want to delete the Booking ${state.deleteBooking?.bookingId}?`}
        okButtonText="Delete"
        isOpen={state.isDeleteModalOpen}
        isError={updateMutation.isError}
        error={updateMutation.error}
        isOkButtonDisabled={updateMutation.isPending}
        onClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onOk={() =>
          isPresent(state.deleteBooking?.id) &&
          updateMutation.mutate(state.deleteBooking.id)
        }
      />
      <Modal
        opened={state.isEditModalOpen}
        onRequestClose={() => {}}
        getAppElement={getAppRoot}
      >
        <StyledDialog
          onClose={() => {
            handleCloseEditModal();
          }}
        >
          <EditBookingForm
            booking={state.editBooking}
            onSave={() => {
              dispatch({
                type: 'closeEditModal',
              });
            }}
            onCancel={() => {
              dispatch({
                type: 'closeEditModal',
              });
            }}
          />
        </StyledDialog>
      </Modal>
    </>
  );
};
