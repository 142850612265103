import { useRef, useState } from 'react';
import styled from 'styled-components';

import { TextInput } from '@rea-group/construct-kit-core';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { DatePickerContainer } from './DatePicker.styles';
import { useHandleClickOutside } from './useHandleClickOutside';

const SingleDatePickerContainer = styled.div`
  position: relative;
  width: 200px;
`;

interface SingleDatePickerProps {
  placeHolder: string;
  date: Date | undefined;
  name?: string;
  className?: string;
  onDateChange: (date: Date | undefined) => void;
}

const SingleDatePicker = ({
  placeHolder,
  date,
  name,
  className,
  onDateChange,
}: SingleDatePickerProps): JSX.Element => {
  const datePickerContainer = useRef<HTMLDivElement>(null);
  useHandleClickOutside(datePickerContainer, () => setIsShowDatePicker(false));

  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

  const handleDaySelect = (date: Date | undefined): void => {
    onDateChange(date);
    setIsShowDatePicker(false);
  };

  const togglePickerVisibility = (): void => {
    setIsShowDatePicker(!isShowDatePicker);
  };

  return (
    <SingleDatePickerContainer ref={datePickerContainer} className={className}>
      <TextInput
        sizeVariant="medium"
        label="Select date"
        hideLabel={true}
        name={name}
        placeholder={placeHolder}
        value={date && format(date, 'dd/MM/y')}
        onFocus={togglePickerVisibility}
      />
      {isShowDatePicker && (
        <DatePickerContainer>
          <DayPicker
            mode="single"
            defaultMonth={date}
            selected={date}
            onSelect={handleDaySelect}
            disabled={{ after: new Date() }}
          />
        </DatePickerContainer>
      )}
    </SingleDatePickerContainer>
  );
};

export default SingleDatePicker;
