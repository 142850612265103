import { H1, Stack } from '@rea-group/construct-kit-core';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import JobQueueComplete from '../components/JobQueueComplete';
import ms from 'ms';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import { Job, JobsTable } from '../components/JobsTable';

export interface JobsResponse {
  jobs: Job[];
  isComplete: boolean;
  totalJobCount: number;
}

const JobQueue = (): JSX.Element => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['jobsData'],
    queryFn: () => fetchJson<JobsResponse>(`/jobs`),
    placeholderData: keepPreviousData,
    refetchInterval: ms('3s'),
  });

  const { jobs, isComplete } = data ?? {};

  return (
    <Stack gap="threeExtraLarge">
      <H1>Job Queue</H1>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {jobs && (
        <Stack gap="small">
          <JobsTable jobs={jobs} />
          {isComplete && <JobQueueComplete />}
        </Stack>
      )}
    </Stack>
  );
};

export default JobQueue;
