import { MutableRefObject, useEffect } from 'react';

const useHandleClickOutside = (
  node: MutableRefObject<HTMLDivElement | null>,
  callback: () => void,
): void => {
  useEffect(() => {
    const handleOutsideClick = ({ target }: Event): void => {
      if (!node || (node.current && node.current.contains(target as Node))) {
        // inside click or undefined
        return;
      }
      // outside click
      callback();
    };
    // add when mounted
    document.addEventListener('click', handleOutsideClick, true);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  });
};

export { useHandleClickOutside };
