import {
  Button,
  H1,
  Inline,
  PillTab,
  PillTabList,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
} from '@rea-group/construct-kit-core';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import { useState } from 'react';
import { DateRange } from 'react-day-picker/src/types';
import { isPresent } from '../utils/helpers';
import RangeDatePickerWithPresets from '../components/DatePicker/RangeDatePickerWithPresets';
import { BookingsTable } from '../components/bookings/BookingsTable';
import { Booking } from '../components/bookings/types';
import { Job, JobsTable } from '../components/JobsTable';
import styled from 'styled-components';
import { fetchJson } from '../API/fetch';
import { useQuery } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import ErrorAlert from '../components/ErrorAlert';

const StyledStack = styled(Stack)`
  overflow-x: scroll;
`;

const getBooking = async (
  isValid: boolean = false,
  startDate?: string,
  endDate?: string,
): Promise<Booking[]> => {
  const urlParams = new URLSearchParams();
  urlParams.append('isValid', String(isValid));

  if (isPresent(startDate)) {
    urlParams.append('startDate', startDate);
  }
  if (isPresent(endDate)) {
    urlParams.append('endDate', endDate);
  }

  const queryStr = `${urlParams.toString()}`;
  return await fetchJson<Booking[]>(`/bookings?${queryStr}`);
};

const JobSummary = (): JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const {
    isLoading,
    refetch,
    isError,
    error,
    data: bookings,
  } = useQuery({
    queryKey: ['getInvalidBookings'],
    queryFn: () =>
      getBooking(
        false,
        dateRange.from
          ? formatISO(dateRange.from, { representation: 'date' })
          : undefined,
        dateRange.to
          ? formatISO(dateRange.to, { representation: 'date' })
          : undefined,
      ),
    initialData: [],
  });

  const jobs: Job[] = [
    {
      id: 1,
      bookingId: '7e63973d-07a0-4219-a75d-3f757dab3498',
      imagesLink: 'image-preview',
      facebookLink:
        'https://business.facebook.com/ads/manager/account/campaigns/?act=462514400783354&selected_ad_set_ids=120219802130340370&selected_campaign_ids=120219802129770370&pid=p1&open_tray=EDITOR_DRAWER&business_id=304955636539232',
      yahooLink: 'N/A',
      yahooNativeLink: 'N/A',
      adformLink: 'N/A',
      tradedeskLink:
        'https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/c5yhhr3/details',
      listingId: 146970484,
      status: 'Error',
    },
    {
      id: 2,
      bookingId: '3d92228a-9feb-4325-9e59-7f65b31f052f',
      imagesLink: 'image-preview',
      facebookLink:
        'https://business.facebook.com/ads/manager/account/campaigns/?act=462514400783354&selected_ad_set_ids=120219802132440370&selected_campaign_ids=120219802132140370&pid=p1&open_tray=EDITOR_DRAWER&business_id=304955636539232',
      yahooLink: 'N/A',
      yahooNativeLink: 'N/A',
      adformLink: 'https://flow.adform.com/campaign/3640007/overview',
      tradedeskLink: 'N/A',
      listingId: 146927100,
      status: 'Error',
    },
    {
      id: 3,
      bookingId: '811ba7b9-ecaf-484e-ac54-be5562c246fc',
      imagesLink: 'image-preview',
      facebookLink:
        'https://business.facebook.com/ads/manager/account/campaigns/?act=462514400783354&selected_ad_set_ids=120219802132540370&selected_campaign_ids=120219802132230370&pid=p1&open_tray=EDITOR_DRAWER&business_id=304955636539232',
      yahooLink: 'N/A',
      yahooNativeLink: 'N/A',
      adformLink: 'N/A',
      tradedeskLink:
        'https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/w31keuf/details',
      listingId: 700341092,
      status: 'Error',
    },
    {
      id: 443656,
      bookingId: 'd5116c36-1bc1-4c45-b7b3-cc46e9ef476b',
      imagesLink: 'image-preview',
      facebookLink:
        'https://business.facebook.com/ads/manager/account/campaigns/?act=462514400783354&selected_ad_set_ids=120219802129080370&selected_campaign_ids=120219802128840370&pid=p1&open_tray=EDITOR_DRAWER&business_id=304955636539232',
      yahooLink: 'N/A',
      yahooNativeLink: 'N/A',
      adformLink: 'https://flow.adform.com/campaign/3639984/overview',
      tradedeskLink: 'Too many requests',
      listingId: 146906808,
      status: 'Error',
    },
  ];

  return (
    <Stack gap="threeExtraLarge">
      <H1>Job Summary</H1>
      <Tabs defaultSelectedTab={0}>
        <Inline gap="extraSmall" alignItems="start">
          <div style={{ width: '200px' }}>
            <PillTabList scrollInset="none">
              <PillTab>Bookings</PillTab>
              <PillTab>Jobs</PillTab>
            </PillTabList>
          </div>
          <div style={{ width: '200px' }}>
            <Inline gap="extraSmall" alignItems="end">
              <RangeDatePickerWithPresets
                placeHolders={{ from: 'Start date', to: 'End date' }}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
              <Button
                variant="primary"
                sizeVariant="medium"
                disabled={
                  !isPresent(dateRange.from) || !isPresent(dateRange.to)
                }
                onClick={() => {
                  refetch().catch((error) => {
                    console.error('Error during refetch:', error);
                  });
                }}
              >
                Filter
              </Button>
            </Inline>
          </div>
        </Inline>
        <TabPanels>
          <TabPanel>
            {isLoading ? (
              <PageLoadingSpinner />
            ) : (
              <StyledStack gap="medium">
                {isError && <ErrorAlert error={error} />}
                <BookingsTable bookings={bookings} />
              </StyledStack>
            )}
          </TabPanel>
          <TabPanel>
            {isLoading ? (
              <PageLoadingSpinner />
            ) : (
              <StyledStack gap="medium" alignItems="end">
                <JobsTable jobs={jobs} />
              </StyledStack>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default JobSummary;
