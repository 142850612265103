import { H1, Stack } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import PackageForm from '../../components/packages/PackageForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PackageRequest } from '../../components/packages/types';
import { useNavigate } from 'react-router-dom';
import { PackageResponse } from './types';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../../components/ErrorAlert';
import { fromPackageResponse } from '../../components/packages/helpers';

const PackageContainer = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`;

const createPackage = (values: PackageRequest): Promise<PackageResponse> =>
  fetchJson<PackageResponse>(`/packages`, {
    method: 'POST',
    body: JSON.stringify(values),
  });

const CreatePackage = (): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createPackage,
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ['editPackage', data.id.toString()],
        fromPackageResponse(data),
      );
      await queryClient.invalidateQueries({ queryKey: ['packagesData'] });

      const result = navigate('/packages');

      if (result instanceof Promise) {
        await result;
      }
    },
  });

  return (
    <PackageContainer>
      <Stack>
        <H1>Create package</H1>
        {isError && <ErrorAlert error={error} />}
        <PackageForm onSave={mutate} isLoading={isPending} />
      </Stack>
    </PackageContainer>
  );
};

export default CreatePackage;
